@import "variables";

/**
* FONT
**/
@font-face 
{
    font-family: Poppins-Light;
    src: url('../fonts/poppins/Poppins-Light.ttf'); 
}

* {    
    font-family: Poppins-Light;
}

@import "variables";
/** * FONT **/
 @font-face {
     font-family: Poppins-Light;
     src: url('../fonts/poppins/Poppins-Light.ttf');
}
 * {
     font-family: Poppins-Light;
}
body {
     background: linear-gradient(90deg, #ebebeb, #d2d2d2);
     //background: linear-gradient(90deg, #144C50, #477B7F);
     //background: linear-gradient(90deg, $end-gradient, $start-gradient);
}

body:has( div .login-background-color-1)
{
    background: linear-gradient(90deg, rgb(60, 130, 137), rgb(111, 179, 167));
}

body:has( div .login-background-color-2)
{
    background: linear-gradient(90deg, rgb(227, 95, 179), rgb(244, 141, 198));
}

body:has( div .login-background-color-3)
{
    background: linear-gradient(90deg, rgb(207, 41, 63), rgb(230, 86, 105));
}

body:has( div .login-background-color-4)
{
    background: linear-gradient(90deg, rgb(188, 172, 164), rgb(235, 231, 223));
}

body:has( div .login-background-color-5)
{
    background: linear-gradient(90deg, rgb(211, 142, 172), rgb(247, 198, 212));
}

.login-card
{
    box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.08);
}

body 
{
    font-family: "Karla", sans-serif;
    background-color: #d0d0ce;
    min-height: 100vh;
}

.brand-wrapper 
{
    margin-bottom: 19px;
}
 .brand-wrapper .logo 
 {
    height: 37px;
	width: 53px;
}

 .login-card 
 {
	margin: 0 auto;
    border: 0;
    border-radius: 20px;
    /*box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);*/
    box-shadow: 0 10px 30px 0 rgba(56, 56, 56, 0.43);
    /*max-height: 480px;*/
}

.login-card-img 
{	
	max-height: 482px;	
    border-radius: 20px 0px 0px 20px;
    margin-left: -1px;
    
}

.login-card .card-body 
{
    
}

@media (max-width: 422px) 
{
    .login-card .card-body 
    {
        padding: 35px 24px;
    }
}

.login-card-description 
{
    font-size: 25px;
    color: #000;
    font-weight: normal;
    margin-bottom: 23px;
}

.login-card form 
{
    max-width: 326px;
}

.login-card .form-control 
{
    border: 1px solid #d5dae2;
    padding: 15px 25px;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: normal;
}

.login-card .form-control:focus 
{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(13, 110, 253, 0.05);
}

.login-card .form-control::-webkit-input-placeholder 
{
    color: #919aa3;
}

.login-card .form-control::-moz-placeholder 
{
    color: #919aa3;
}

.login-card .form-control:-ms-input-placeholder 
{
    color: #919aa3;
}
 
.login-card .form-control::-ms-input-placeholder 
{
    color: #919aa3;
}

.login-card .form-control::placeholder 
{
    color: #919aa3;
}
 
.login-card .login-btn 
{
    padding: 13px 20px 12px;
    background-color: #000;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    margin-bottom: 24px;
}

.login-card .login-btn:hover 
{
    border: 1px solid #000;
    background-color: transparent;
    color: #000;
}
 
.login-card .forgot-password-link 
{
    font-size: 14px;
    color: #919aa3;
    margin-bottom: 12px;
}
 
.login-card-footer-text 
{
    font-size: 16px;
    color: #0d2366;
    margin-bottom: 60px;
}
 
@media (max-width: 767px) 
{
    .login-card-footer-text 
    {
        margin-bottom: 24px;
    }
}
 
.login-card-footer-nav a 
{
    font-size: 14px;
    color: #919aa3;
}






.login-card
{
	width: 660px;
}

.login-card .card-body
{
	//padding-top: 55px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.login-container 
{
	height: 400px;
	margin: auto;
}
  
.login-card-left 
{
	width: 260px;
	height: 100%;
	float: left;
}

.login-card-right 
{
	width: 400px;
	height: 100%;
	padding-left: 20px;
	float: left;
}

.login-card-img
{
	width: 260px;
	height: 400px;
}

@media (max-width: 991px) 
{
    .login-card-left
    {
		display: none;
    }

	.login-card
	{
		width: 300px;
	}

	.login-card-right 
	{
		width: 300px;
		height: 100%;
		padding-left: 0px;
		float: left;
	}
}